import { Grid } from '@material-ui/core';
import React from 'react';
import CaseInformation from '../caseinfo/CaseInformation';
import { AdvancedSearch } from './AdvancedSearch';
import { SearchResult } from './SearchResult';
import { getCase, deleteCase, listCases, listCaseTypes, getCaseProtocol } from '../rest/RESTUtils';
import { addCase, getCurrentCaseProtocol } from '../rest/RESTUtils'
import { recordsToTree } from '../util/TreeDataUtils';
import { PureComponent } from 'react';

class Search extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      search: null,
      caseForEdit: null
    }
  }

  init() {
    listCaseTypes(caseTypes => {
      this.setState({
        caseTypes: recordsToTree(caseTypes)
      })
    })
  }

  updateData(search) {
    listCases(search, newData => {
      this.setState({ 
        search: search,
        data: newData
      })
    })
  }

  componentDidMount() {
    this.init()
    this.updateData()
  }

  onSearchPerformed(startDate, endDate, name, caseType, age) {
    this.updateData({startDate: startDate, endDate: endDate, name: name, caseType: caseType, age: age})
  }

  onCaseEdited(caseId) {
    getCase(caseId, (newData) => {
      this.setState({ 
        caseForEdit: newData
      })
    })
  }

  onCaseDeleted(caseId) {
    deleteCase(caseId, () => {
      this.updateData(this.state.search)
    })
  }

  onExportProtocol(caseId) {
    getCaseProtocol(caseId, (blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'protocol.pdf');
      
      document.body.appendChild(link);
      
      link.click();
      
      link.parentNode.removeChild(link)
    })
  }

  render () {
    if (this.state.caseForEdit) {
      return <CaseInformation caseInformation={this.state.caseForEdit}
        addCase={addCase}
        getCurrentCaseProtocol={getCurrentCaseProtocol}
        onBackPerformed={() => this.setState({ 
          caseForEdit: null
        })}/>;
    } else {
      return (
        <>
          {this.state.caseTypes && (
            <Grid container justify="space-around" alignItems="center" spacing={3}>
              <Grid item xs={3}>
                <AdvancedSearch caseTypes={this.state.caseTypes}
                  onSearchPerformed={(startDate, endDate, name, caseType, age) => this.onSearchPerformed(startDate, endDate, name, caseType, age)}/>
              </Grid>
              <Grid item xs={9}>
                <SearchResult rows={this.state.data} 
                  caseTypes={this.state.caseTypes}
                  onCaseEdited={(caseId) => this.onCaseEdited(caseId)}
                  onCaseDeleted={(caseId) => this.onCaseDeleted(caseId)}
                  onExportProtocol={(caseId) => this.onExportProtocol(caseId)}/>
              </Grid>
            </Grid>
          )}
        </>
      );
    }
  }
}

export default Search;
